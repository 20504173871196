.card {
  display: flex;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin: 20px;
  border-radius: 8px;
  width: 20vw;
  max-width: 200px;
  height: 20vh;
  max-height: 360px;
}

@media (max-width: 768px) {
  .card {
    width: 100%;
  }
}
