.riservato {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.riservatoBg {
  margin-top: 5vh;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.riservatoBg h1 {
  margin: 50px;
}
.riservatoBg input {
  background-color: white;
  width: 300px;
  height: 20px;
  border: none;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 80px;
  padding: 20px;
  margin: 10px;
}
.riservatoButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background-color: #000;
  color: #fff;
  width: 300px;
  height: 20px;
  border: none;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 80px;
  padding: 20px;
  margin: 10px;
  cursor: pointer;
}
