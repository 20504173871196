.navbar {
  position: fixed;
  display: flex;
  flex-direction: row;
  width: 80%;
  min-height: 60px;
  justify-content: space-between;
  align-items: center;
  top: 0;
  margin: 20px 0;
  padding: 10px 40px;
  box-sizing: border-box;
  border-radius: 80px;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 10;
}
.logo {
  min-width: 100px;
  max-height: 16px;
}
.pages {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.option {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  min-width: 100px;
}
.hamburgerIcon {
  cursor: pointer;
}
