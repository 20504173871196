.emailButton {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #000;
  color: #fff;
  width: 200px;
  height: 20px;
  border: none;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 80px;
  padding: 20px;
  margin: 40px;
  cursor: pointer;
  min-width: max-content;
}
