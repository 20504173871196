.homePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  overflow: hidden;
}
/* .bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -1;
} */

.intro {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  min-width: 100%;
  box-sizing: border-box;
  padding: 0 15vw;
  width: 60vw;
  gap: 50px;
  text-align: center;
  margin: 180px auto;
  text-align: left;
}
.introH1 {
  max-width: 400px;
}
.homeImage {
  display: block;
  max-width: 300px;
}
.cosafacciamo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 420px;
  text-align: center;
  margin: 140px auto;
  padding: 150px 0 0 0;
}
.serviziHome {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
  margin: 0 10vw;
}

.assistenza {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 420px;
  /* width: 768px; */
  margin: 10%;
  text-align: center;
}

.assistenzaIcon {
  display: flex;
  gap: 5vw;
  margin: 40px 0;
  height: 100px;
}

.why {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 768px;
  text-align: center;
  margin: 100px auto;
}
.whyOptions {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  height: 200px;
  max-width: 100%;
  text-align: center;
  margin: 40px 0;
}
.whyOption {
  max-width: 200px;
}
.divider {
  height: 100%;
  width: 1px;
  margin: 0 30px;
  background-color: #b0b0b0;
}
.preventivo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 100%;
  text-align: center;
  margin-bottom: 40px;
}
.gotoIniziaButton {
  /* background-color: #92d6b6; */
  background-color: #4169e1;
  color: white;
  padding: 20px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  cursor: pointer;
}
.miniText {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 12px;
  color: #b0b0b0;
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .intro {
    margin: 140px 20px;
  }
  .cosafacciamo {
    margin: 80px 20px;
  }
  .assistenza {
    margin: 80px 20px;
  }
  .servizi {
    display: flex;
    flex-direction: column;
  }
  .whyOptions {
    flex-direction: column;
    height: auto;
  }
}
@media screen and (max-width: 900px) {
  .homeImage {
    display: none;
  }
  .intro {
    justify-content: center;
    text-align: center;
  }
  .servizi {
    grid-template-columns: repeat(2, 1fr);
  }
}
