@font-face {
  font-family: "CalSans"; /* You can name it whatever you like */
  src: url("./assets/fonts/CalSans-SemiBold.otf") format("opentype");
}

html {
  background-color: #f5f5f5;
  color: #333333;
}
body {
  font-family: "CalSans", sans-serif;
}

.center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
p {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue";
  /* max-width: 100%; */
}

/* @media (max-width: 768px) {
  .html {
    margin: 0 20px;
  }
} */
