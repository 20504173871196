.footerPage {
  display: flex;
  justify-content: flex-start;
  align-items: space-between;
  flex-direction: column;
  background-color: #1a1a1a;
  color: #fff;
  padding: 20px 0;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  width: 100%;
  height: 300px;
  min-height: max-content;

  margin-top: 40px;
  padding: 60px 190px 20px 60px;
  box-sizing: border-box;
}
.footerInfo {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 180px;
}
.footerItem {
  text-align: left;
}
.footerItem h4 {
  font-size: 16px;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}
.dividerFooter {
  width: 100%;
  min-height: 1px;
  background-color: #b5b5b54c;
}
.infoLegali {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 40px;
  padding-top: 10px;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .footerPage {
    padding: 60px 20px 20px 20px;
    height: max-content;
  }
  .footerInfo {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: max-content;
  }
  .footerLogo {
    width: 100%;
    margin-bottom: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footerItem {
    text-align: left;
    margin-bottom: 20px;
  }
  .infoLegali {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: max-content;
  }
  .infoLegali p {
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
  }
  .infoLegali a {
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
  }
  .dividerFooter {
    display: none;
  }
}
