.bigCard {
  height: 280px;
  max-width: 400px;
  display: flex;
  margin: 10px;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
