.ripple-button {
  position: relative;
  overflow: hidden;
  /* Your styling for the button */
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #101010;
  color: white;
  padding: 9px 20px 5px 20px;
  font-family: "CalSans", sans-serif;
  line-height: 2;
  z-index: 1;
}

.gotoIniziaButtonCard {
  position: relative;
  overflow: hidden;
  /* Your styling for the button */
  border: none;
  background-color: #101010;
  color: white;
  font-family: "CalSans", sans-serif;
  line-height: 2;
  font-size: larger;
  z-index: 1;
  height: 280px;
  max-width: 400px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 15px 20px 40px 20px;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  line-height: 1.7rem;
}

.ripple {
  position: absolute;
  border-radius: 50%;
  background: rgba(190, 190, 190, 0.6);
  transform: scale(0);
  animation: ripple-animation 0.6s linear;
  pointer-events: none;
}

@keyframes ripple-animation {
  to {
    transform: scale(4);
    opacity: 0;
  }
}
