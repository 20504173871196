.fullPage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 12vh;
}

.fullBg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10vh 20vh 40vh 20vh;
}

.carica {
  color: #fff;
  border-radius: 50px;
  padding: 20px 50px;
  flex-wrap: wrap;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
