.serviziPage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.servizi {
  margin: 150px 0;
  display: flex;
  flex-direction: row;
  height: max-content;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10vw;
  min-height: 65vh !important;
}
.serviziMain {
  max-width: 450px;
}

.serviziMinicards {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 80px 0 20px 0;
}
.serviziInfo {
  position: relative;
  overflow: hidden;
  height: max-content;
  width: 400px; /* You can set this to whatever suits your design */
}

.slide-content {
  display: flex;
  transition: transform 0.5s ease-out;
}

.slide-item {
  flex: 0 0 400px;
}

.slideItemTop {
  width: 100%;
  height: max-content;
  margin: 0 0 30px 0;
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
  max-width: 250px;
}
.slide-item img {
  width: 90px;
  height: 90px;
  object-fit: cover;
}
.serviziTitle {
  display: flex;
}
.serviziTitles {
  display: flex;
}
.serviziTop {
  display: flex;
  align-items: center;
  margin: 0 30px;
}
.sliderArrows {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.arrow {
  cursor: pointer;
  margin: 20px;
}

.serviziTitles,
.slide-content {
  transition: transform 0.3s ease-out;
}
@media screen and (max-width: 768px) {
  .servizi {
    flex-direction: column;
    transform: scale(0.8);
  }
}
